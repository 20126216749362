
import { reactive, ref } from "vue"
import FilterOverlay from "@/components/data/FilterOverlay.vue"

export default {
  name: "LogsFilter",
  components: { FilterOverlay },
  props: {
    activeFilters: {
      type: Object,
    },
    originOptions: {
      type: Set,
      default: null,
    },
  },
  emits: ["reload"],
  setup(props: any, { emit }: any) {
    const severityOptions = reactive(["DEBUG", "ERROR", "INFO", "WARNING"])
    const selectedSeverities = ref()
    const selectedOrigins = ref()
    const message = ref()
    const timeout = ref(null)

    function filterSeverity(event: any) {
      let activeFiltersAfterRemove = props.activeFilters.filter(
        (activeFilter: any) => activeFilter.field !== "severity"
      )
      props.activeFilters.length = 0
      activeFiltersAfterRemove.forEach((item: any) => {
        props.activeFilters.push(item)
      })

      /*event.value.forEach((severity: any) => {
        props.activeFilters.push({
          field: "severity",
          type: "equals",
          compareValue: severity,
          path: (row: any) => row["severity"],
          operator: "=",
        })
      })*/
      if (event.value && event.value.length) {
        props.activeFilters.push({
          field: "severity",
          type: "equals",
          compareValue: event.value,
          path: (row: any) => row["severity"],
          operator: "=",
        })
      }

      emit("reload")
    }

    function filterOrigin(event: any) {
      let activeFiltersAfterRemove = props.activeFilters.filter(
        (activeFilter: any) => activeFilter.field !== "origin"
      )
      props.activeFilters.length = 0
      activeFiltersAfterRemove.forEach((item: any) => {
        props.activeFilters.push(item)
      })

      /*event.value.forEach((origin: any) => {
        props.activeFilters.push({
          field: "origin",
          type: "equals",
          compareValue: origin,
          path: (row: any) => row["origin"],
          operator: "=",
        })
      })*/
      if (event.value && event.value.length) {
        props.activeFilters.push({
          field: "origin",
          type: "equals",
          compareValue: event.value,
          path: (row: any) => row["origin"],
          operator: "=",
        })
      }

      emit("reload")
    }

    function filterMessage() {
      clearTimeout(timeout.value)
      timeout.value = setTimeout(() => {
        if (message.value !== null) {
          let messageField = props.activeFilters.find((filter: any) => filter.field === "message")
          if (messageField) {
            messageField.compareValue = message.value
          } else {
            props.activeFilters.push({
              field: "message",
              type: "subString",
              compareValue: message.value,
              path: (row: any) => row["message"],
              operator: "=",
            })
          }
          emit("reload")
        }
      }, 500)
    }

    function resetFilters() {
      message.value = ""
      selectedOrigins.value = null
      selectedSeverities.value = null
      props.activeFilters.length = 0
      emit("reload")
    }

    function resetSingleFilter(fieldString: string) {
      let activeFiltersAfterRemove = props.activeFilters.filter(
        (activeFilter: any) => activeFilter.field !== fieldString
      )
      props.activeFilters.length = 0
      activeFiltersAfterRemove.forEach((item: any) => {
        props.activeFilters.push(item)
      })
      emit("reload")
    }

    return {
      severityOptions,
      selectedSeverities,
      selectedOrigins,
      filterSeverity,
      filterOrigin,
      message,
      filterMessage,
      resetFilters,
      resetSingleFilter,
    }
  },
}
