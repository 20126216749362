
import ComputerService from "@/services/ComputerService"
import useTimeUtils from "@/utils/time"
import { nextTick, onMounted, onUnmounted, reactive, ref } from "vue"
import LogsFilter from "@/components/logs/tools/LogsFilter.vue"

export default {
  name: "ComputerLogs",
  components: { LogsFilter },
  props: {
    computer: Object,
  },
  emits: ["value-changed"],
  setup(props: any, { emit }: any) {
    const computerService = new ComputerService()
    let part = 0
    const logs = reactive([])
    const allLogs = reactive([])
    const loadedAllData = ref(false)
    const loadingPart = ref(false)
    const scrollComponent = ref()
    const { absoluteDateTime } = useTimeUtils()
    const activeFilters = reactive([])
    const allOrigins = reactive(new Set())

    const comparators: any = reactive({
      equals: (a: string, b: any) => {
        if (Array.isArray(b) && b.length) {
          return b.includes(a)
        }
        return a === b
      },
      subString: (a: string, b: string) =>
        a ? a.toLowerCase().indexOf(b.toLowerCase()) !== -1 : null,
    })

    loadLogs()

    onMounted(() => {
      scrollComponent.value = document.querySelector(".computer-logs-body")
      if (scrollComponent.value) {
        scrollComponent.value.addEventListener("scroll", checkVisible, { passive: true })
      }

      window.addEventListener("resize", checkVisible, { passive: true })
    })

    onUnmounted(() => {
      if (scrollComponent.value) {
        scrollComponent.value.removeEventListener("scroll", checkVisible)
      }

      window.removeEventListener("resize", checkVisible)
    })

    function checkVisible() {
      if (loadedAllData.value) return
      if (loadingPart.value) return

      if (
        Math.ceil(scrollComponent.value.scrollTop * 1.3) >=
        scrollComponent.value.scrollHeight - scrollComponent.value.clientHeight
      ) {
        loadLogs()
      }
    }

    function loadLogs() {
      loadingPart.value = true
      computerService.getComputerLogs(props.computer.id, part, null).then((result) => {
        loadedAllData.value = !result.moreToCome
        loadingPart.value = false

        if (result.rows.length == 0) {
          return
        }

        part++

        result.rows
          .filter((row) => !row.deleted)
          .forEach((element) => {
            allLogs.push(element)
            allOrigins.add(element.origin)
          })

        logs.length = 0
        if (activeFilters.length) {
          allLogs.filter(filterList).forEach((log) => {
            logs.push(log)
          })
        } else {
          allLogs.forEach((log) => {
            logs.push(log)
          })
        }

        nextTick().then(() => {
          checkVisible()
        })
      })
    }

    function reload() {
      loadingPart.value = true
      part = 0
      loadedAllData.value = false
      logs.length = 0
      allLogs.length = 0
      loadLogs()
    }

    const filterList = (row: any) => {
      return activeFilters
        .map((filter: any) => {
          return !!comparators[filter.type](filter.path(row), filter.compareValue)
        })
        .every((isTrue) => isTrue)
    }

    function openFujiPanelInfo(event: any) {
      if (event && event.stopPropagation) event.stopPropagation()
      window.open(" https://vsol.slab.com/posts/xc406pt2", "_blank")
    }

    return {
      logs,
      absoluteDateTime,
      openFujiPanelInfo,
      activeFilters,
      allOrigins,
      reload,
    }
  },
}
